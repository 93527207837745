<template>
    <div>
        <div class="renderer">
            <slot name="Start" />
        </div>
        <div class="renderer">
            <div class="global">{{ arrondir(noteGlobale.noteValue) }}</div>
        </div>
        <div class="renderer">
            <slot name="Middle">
                <b-button class="nextStep" variant="primary" size="sm" @click="nextStep">Suivant</b-button>
            </slot>
        </div>
        <div class="renderer" v-if="cfgVerboseNoteSummary">
            <div v-for="judgeNote in notesPerJudges" :key="judgeNote.judge_id">
                <div class="judgeNote">
                    <div class="judgeName">{{ judgeNote.judge.shortname}}</div>
                    <div class="note">{{ judgeNote.noteEntry }}</div>
                    <div>
                <b-button
                    @click="modifyJudgeNote(judgeNote.judge_id)"
                    class="modifyJudgeNote"
                    variant="secondary"
                >
                    Modifier
                </b-button>
                </div>
                </div>
            </div>
        </div>
        <div class="renderer">
            <slot name="End" />
        </div>
    </div>
</template>

<script>
import { RST_COMPETITOR_COMPULSORY_NOTE } from "@/LocalDB";


export default {
    props: {
    },
    data(){
        return {
            noteGlobale: '',
            notesPerJudges: Array,
        };
    },
    computed:{
//        ...mapGetters('currentState', ['currentCategory', 'currentCompetitor', 'currentFigure'] ),
    },
    methods:{
        refresh(){
            this.noteGlobale = RST_COMPETITOR_COMPULSORY_NOTE.query()
                                .where("competition_id", this.currentEvent.competition_id)
                                .where("round_number", this.currentEvent.round_number)
                                .where("level", this.currentEvent.level)
                                .where('category', this.currentEvent.category)
                                .where('competitor_id', this.currentEvent.current_competitor_id)
                                .where('compulsory_id', this.currentEvent.current_compulsory_id)
                                .first();

            //this.noteGlobale = figureNotes.filter(w => w.judge_id === null)[0];
            //this.notesPerJudges = figureNotes.filter(w => w.judge_id !== null);
        },
        modifyJudgeNote(judgeId){
            this.$emit("modifyJudgeNote", judgeId);
        },
        nextStep(){
            this.$emit("nextStep");
        },
    },
    mounted(){
        this.refresh();
    }
}
</script>

<style scoped>
    .renderer{
        display:flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
    }
    .global{
        display:flex;
        justify-content:center;
        align-items:center;
        font-size:2.5em; border:solid 1px black; color:black; font-weight: bold; width:130px; height:90px;
    }
    .judgeNote{
        display:flex;
        flex-direction:column;
        justify-content: space-between;
        align-items: center;
        /*flex-wrap: wrap;*/
        font-size:1.2em; border: solid 1px darkgray; color:darkgray;
        width:100px; height:70px;
        margin: 20px 10px;
        padding: 2px 0px;
    }
    .judgeName{
        font-style: italic; font-size:0.7em;
    }
    .note{
        font-weight: bold;
    }
    .nextStep{
        font-size:2em;
            /*border:solid 4px darkgreen;*/
            /*background-color:lightgreen; color:darkgreen;*/
        margin: 20px 0px;
        padding:4px;
    }
    .modifyJudgeNote{
        font-size:0.7em;
        margin: 2px;
    }
</style>
